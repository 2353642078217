/* Footer.css */
h2 {
  padding: 25px 0;
  text-align: center;
  /* color: #fff;
  background: #7c8490; */
}
footer {
  background: rgb(9, 95, 234);
  color: white;
  margin-top: 20px;
  height: auto;
}
footer a {
  color: #fff;
  font-size: 14px;
  transition-duration: 0.2s;
  text-decoration: none;
}
footer a:hover {
  color: #FA944B;
  text-decoration: none;
}
.copy {
  font-size: 12px;
  padding: 10px;
  border-top: 1px solid #FFFFFF;
}
.footer-middle {
  padding-top: 2em;
  color: white;
}
ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}
ul.social-network li {
  display: inline;
  margin: 0 5px;
}
.social-network a.icoFacebook:hover {
  background-color: #3B5998;
}
.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}
.social-network a.icoFacebook:hover i {
  color: #fff;
}
.social-network a.icoLinkedin:hover i {
  color: #fff;
}
.social-network a.socialIcon:hover {
  color: #44BCDD;
}
.socialHoverClass {
  color: #44BCDD;
}
.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}
.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}
.social-circle li a:hover i {
  transform: rotate(360deg);
  transition: all 0.2s;
}
.triggeredHover {
  transform: rotate(360deg);
  transition: all 0.2s;
}
.social-circle i {
  color: #595959;
  transition: all 0.8s;
}
.social-network a {
  background-color: #F9F9F9;
}
.social-network a:hover {
  background: #ff304d;
}
