.section-title {
  font-size: 1.8rem; /* Matches the bold heading size */
  font-weight: bold;
  margin-bottom: 0.8rem;
  text-align: start;
  /* text-decoration: underline; Underline for section title */
}

.section-text {
  font-size: 1.1rem; /* Matches the body text size */
  margin-bottom: 1.2rem;
  line-height: 1.6;
  color: #6c757d; /* Muted text color similar to Bootstrap */
}

/* Adjust the title and text size for smaller screens */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.5rem;
  }

  .section-text {
    font-size: 1rem;
  }
}
